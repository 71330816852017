export const onServiceWorkerUpdateReady = () => {
  window.location.reload();
};

export const onInitialClientRender = () => {
  function floaty() {
    document.onscroll = () => {
      updatefloat_positions();
    };
    document.onresize = function () {
      updatefloat_positions();
    };
    updatefloat_positions();
  }

  function updatefloat_positions() {
    var floaty = document.querySelectorAll(".floaty");
    Array.prototype.forEach.call(floaty, function (el, i) {
      var y = parseInt(el.getAttribute("data-scroll-speed-y"));
      var x = parseInt(el.getAttribute("data-scroll-speed-x"));
      var offsetY = 0;
      var offsetX = 0;

      if (x && y) {
        offsetY =
          (-1 * (window.scrollY - el.getBoundingClientRect().top / 20)) / y;
        offsetX =
          (-1 * (window.scrollY - el.getBoundingClientRect().top / 20)) / x;
        el.style.transform =
          "translateY(" + offsetY + "px) translateX(" + offsetX + "px)";
      }
      if (x) {
        offsetX =
          (-1 * (window.scrollY - el.getBoundingClientRect().top / 20)) / x;
        el.style.transform = "translateX(" + offsetX + "px)";
      }
      if (y) {
        offsetY =
          (-1 * (window.scrollY - el.getBoundingClientRect().top / 20)) / y;
        el.style.transform = "translateY(" + offsetY + "px)";
      }
    });
  }

  floaty();

  // navBarScroll();
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  // navBarScroll();
  collapseWatch();

  //show intercom on contact page
  // if (location.pathname === '/contact/') {
  //   Intercom('show')
  // }

  //anchor listener for # links
  var Anchors = document.getElementsByTagName("a");

  for (var i = 0; i < Anchors.length; i++) {
    Anchors[i].addEventListener(
      "click",
      function (event) {
        if (event.target.href) {
          var href = event.target.href;
          var hash = "";
          if (location.hash) {
            hash = href.replace(location.href.replace(location.hash, ""), "");
          }
          else {
            hash = href.replace(location.href, "");
          }
          if (hash[0] === "#" && hash !== "#") {
            event.preventDefault();
            if (document.querySelector(hash)) {
              var target = document.querySelector(hash);
              var target_pos = target.getBoundingClientRect();
              var scroll = window.scrollY;
              scrolly(
                document.scrollingElement || document.documentElement,
                "scrollTop",
                "",
                scroll,
                target_pos.top-77,
                300,
                true
              );
            } else if (hash === "#") {
              event.preventDefault();
            }
          }
        }
      },
      false
    );
  }
  //smooth scroll function
  function scrolly(elem, style, unit, from, to, time, prop) {
    if (!elem) {
      return;
    }
    var start = new Date().getTime(),
      timer = setInterval(function () {
        var step = Math.min(1, (new Date().getTime() - start) / time);
        if (prop) {
          elem[style] = from + step * (to - from) + unit;
        } else {
          elem.style[style] = from + step * (to - from) + unit;
        }
        if (step === 1) {
          clearInterval(timer);
        }
      }, 10);
    if (prop) {
      elem[style] = from + unit;
    } else {
      elem.style[style] = from + unit;
    }
  }

  //intercom scroll
  // function intercomScroll() {
  //   window.onscroll = function(e) {
  //     if (window.scrollY > 100 && location.pathname === '/contact/') {
  //       Intercom('hide')
  //     } else {
  //       Intercom('show')
  //     }
  //   }
  // }

  //hubsport
  // var hubSpot = document.querySelector('#hubspotform')
  // if (hubSpot) {
  //   hubSpot.classList.add('hidden')
  //   hubSpot.innerHTML = ''

  //   var portalId = hubSpot.getAttribute('data-portalid')
  //   var formId = hubSpot.getAttribute('data-formid')

  //   hbspt.forms.create({
  //     portalId: portalId,
  //     formId: formId,
  //     target: '#hubspotform',
  //   })
  //   hubSpot.classList.remove('hidden')
  // }

  //scroll spy for features
  // (function () {
  //   var section = document.querySelectorAll(".featurelist-content-item");
  //   var sections_top = {};
  //   var sections_bottom = {};
  //   var i = 0;

  //   Array.prototype.forEach.call(section, function (e) {
  //     sections_top[e.id] = e.getBoundingClientRect().top;
  //     sections_bottom[e.id] = e.getBoundingClientRect().bottom;
  //   });

  //   window.addEventListener("scroll", function () {
  //     if (document.querySelectorAll(".featurelist-content-item").length > 0) {
  //       // console.log(section.length)
  //       var scrollPosition = document.documentElement.scrollTop;
  //       for (i in sections_top) {
  //         if (
  //           sections_top[i] <=
  //             scrollPosition +
  //               (window.innerHeight - window.innerHeight * 0.5) &&
  //           sections_bottom[i] >= scrollPosition + window.innerHeight * 0.5
  //         ) {
  //           // document.querySelector('.featurelist-content-item.active').classList.remove('active');
  //           document
  //             .querySelector("#featureslist .featured_" + i)
  //             .classList.add("active");
  //           document.querySelector("#" + i).classList.add("active");
  //         } else if (section.length > 0) {
  //           document
  //             .querySelector("#featureslist .featured_" + i)
  //             .classList.remove("active");
  //           document.querySelector("#" + i).classList.remove("active");
  //         }
  //       }
  //     }
  //   });
  // })();
};

// function navBarScroll() {
//   window.addEventListener("scroll", function () {
//     // console.log(window.scrollY)
//     if (document.querySelector(".navbar-fixed-top")) {
//       if (window.scrollY > 0) {
//         document.querySelector(".navbar-fixed-top").classList.add("scrolled");
//         // console.log('scroll')
//       } else {
//         document
//           .querySelector(".navbar-fixed-top")
//           .classList.remove("scrolled");
//       }
//     }
//   });
// }

function collapseWatch() {
  // Handler that uses various data-* attributes to trigger
  // specific actions, mimicing bootstraps attributes
  const triggers = Array.from(
    document.querySelectorAll('[data-toggle="collapse"]')
  );

  window.addEventListener(
    "click",
    (ev) => {
      const elm = ev.target;
      // alert('here')
      if (triggers.includes(elm)) {
        const selector = elm.getAttribute("data-target");
        collapse(selector, "toggle");
      }
    },
    false
  );

  const fnmap = {
    toggle: "toggle",
    show: "add",
    hide: "remove",
  };

  const collapse = (selector, cmd) => {
    Array.from(document.querySelectorAll(selector)).forEach((target) => {
      target.classList[fnmap[cmd]]("show");
    });
  };
}
